<template>
  <button
    class="w-4/5 max-w-96 bg-white rounded-full py-3 inline font-semibold"
    @click="isFormVisible = !isFormVisible"
  >
    <slot />
  </button>

  <Teleport v-if="mounted" to="#form-container">
    <Transition>
      <div v-if="isFormVisible" class="p-4 max-w-[600px] m-auto">
        <div class="bg-brand-yellow rounded-xl p-5 m-auto">
          <div class="flex justify-end">
            <button @click="isFormVisible = false">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div v-if="formStatus === 'success'" class="p-4 max-w-[600px] m-auto">
            <div
              class="flex justify-center flex-col md:flex-row items-center gap-5 bg-brand-yellow rounded-xl p-5 m-auto"
            >
              <Checkmark type="success" />
              <h2 class="text-2xl mb-4 font-black">
                Kontaktförfrågan skickad, vi hör av oss så snart vi kan
              </h2>
            </div>
          </div>
          <div
            v-else-if="formStatus === 'error'"
            class="p-4 max-w-[600px] m-auto"
          >
            <div
              class="flex justify-center flex-col md:flex-row items-center gap-5 bg-brand-yellow rounded-xl p-5 m-auto"
            >
              <Checkmark type="error" />
              <h2 class="text-2xl mb-4 font-black">
                Kontaktförfrågan misslyckades, försök gärna igen eller skicka
                mail direkt till hej@livspepp.se
              </h2>
            </div>
          </div>
          <div v-else>
            <h2 class="text-4xl mb-4 font-black">
              Vill du komma i kontakt med oss?
            </h2>
            <ContactForm @success="onSuccess" @error="onError" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import ContactForm from "./ContactForm.vue";
import Checkmark from "./Checkmark.vue";

const mounted = ref(false);
const isFormVisible = ref(false);
const formStatus = ref<"idle" | "success" | "error">("idle");

onMounted(() => {
  mounted.value = true;
});

function onSuccess(_response: any) {
  formStatus.value = "success";

  setTimeout(() => {
    isFormVisible.value = false;
  }, 2000);
}

function onError(err: any) {
  formStatus.value = "error";
  setTimeout(() => {
    formStatus.value = "idle";
  }, 5000);
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: transform 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(100%);
}
</style>
